import lozad from 'lozad'

window.lozad = lozad;

window.observer = window.lozad('.lozad', {
    threshold: 0.1,
    rootMargin: '50px 50px 50px 50px',
    enableAutoReload: true,
    load: function (el) {
        el.closest('picture').querySelectorAll('[data-srcset]').forEach((child) => {
            child.removeAttribute('loading')
            child.setAttribute('srcset', child.getAttribute('data-srcset'))
            child.removeAttribute('data-srcset')
        })
    },
    loaded: function (el) {
        setTimeout(() => {
            const lazyLoadWrap = el.closest('.lazy-load-wrap');
            const placeholder = lazyLoadWrap ? lazyLoadWrap.querySelector('.lazy-load-placeholder') : null

            if (lazyLoadWrap) {
                if (lazyLoadWrap.getAttribute('data-lazy-remove')) lazyLoadWrap.classList.remove(...lazyLoadWrap.getAttribute('data-lazy-remove').split(' '))
                if (lazyLoadWrap.getAttribute('data-lazy-add')) lazyLoadWrap.classList.add(...lazyLoadWrap.getAttribute('data-lazy-add').split(' '))
            }

            if (placeholder) {
                if (placeholder.getAttribute('data-lazy-remove')) placeholder.classList.remove(...placeholder.getAttribute('data-lazy-remove').split(' '))

                if (placeholder.getAttribute('data-lazy-add')) {
                    placeholder.classList.add(...placeholder.getAttribute('data-lazy-add').split(' '))
                } else {
                    if (placeholder.getAttribute('class').indexOf('transition-') === -1) placeholder.classList.add('transition-all')
                    if (placeholder.getAttribute('class').indexOf('duration-') === -1) placeholder.classList.add('duration-500')
                    if (placeholder.getAttribute('class').indexOf('opacity-') === -1) placeholder.classList.add('opacity-0')
                    placeholder.classList.add('invisible')
                }
            }
        }, 250)
    }
});

window.observer.observe();
