// Put custom js code here, which is not possible with AlpineJS
// To load the class create a data-component attribute and register the class in /assets/src/js/components.js

export default class ShopGridForm {
    constructor(rootNode) {
        this.$refs = {
            rootNode,
            form: rootNode.querySelector('form'),
            items: rootNode.querySelectorAll('.item'),
            keyword: rootNode.querySelector('form #keyword'),
            category: rootNode.querySelector('form #category'),
            level: rootNode.querySelector('form #level')
        };

        this.$refs.rootNode.addEventListener('change', async () => {
            await this.filterItems()
        })
    }

    async filterItems() {
        Object.values(this.$refs.items).forEach((item) => {
            item.classList.add('opacity-0')
        })

        await this.sleep(500);

        Object.values(this.$refs.items).forEach((item) => {
            item.classList.add('invisible')
            item.classList.add('absolute')
        })

        const filteredItems = this.getFilteredItems()
        if(filteredItems.length > 0) {
            Object.values(filteredItems).forEach((item) => {
                item.classList.remove('opacity-0')
                item.classList.remove('invisible')
                item.classList.remove('absolute')
            })
        } else {
            Object.values(this.$refs.items).forEach((item) => {
                item.classList.remove('opacity-0')
                item.classList.remove('invisible')
                item.classList.remove('absolute')
            })
        }
    }

    getFilteredItems() {
        const filteredItems = []

        Object.values(this.$refs.items).forEach((item) => {
            if(this.$refs.category.value) {
                if (item.classList.contains('category-' + this.$refs.category.value)) {
                    filteredItems.push(item)
                }
            }

            if(this.$refs.level.value) {
                if (item.classList.contains('level-' + this.$refs.level.value)) {
                    filteredItems.push(item)
                }
            }

            if(this.$refs.keyword.value && this.$refs.keyword.value.length > 3) {
                if (item.getAttribute('data-keywords').toLowerCase().includes(this.$refs.keyword.value.toLowerCase())) {
                    filteredItems.push(item)
                }
            }
        })

        return filteredItems
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
